<template>
  <div :class="`fields-wrap-container ${classes}`">
    <div class="show-title" v-if="formShowTitle">
      {{ formShowTitle }}
    </div>
    <template v-for="(field, index) in _fields">
      <!-- 只读 -->
      <van-cell
        v-if="field.type == 'readonly'"
        :key="`readonly-${index}`"
        :title="field.label"
        :value="getReadonlyVal(field)"
      />
      <!-- 输入 -->
      <van-field
        v-else-if="enterFields.includes(field.type)"
        :key="`enter-${index}`"
        :type="field.type == 'amount' ? 'number' : field.type"
        v-model.trim="formData[field.key]"
        :label="field.label"
        :maxlength="field.maxlength || 50"
        :placeholder="
          field.placeholder ? field.placeholder : `请输入${field.label}`
        "
        :rules="field.required ? required : []"
        :required="field.required"
        :label-width="field.labelWidth ? field.labelWidth : '6.8em'"
        :disabled="field.disabled"
        :input-align="field.align ? field.align : 'right'"
        @input="inputChange($event, field)"
        @blur="
          upperCaseKeys.includes(field.key) ? upperCase(field.key) : void 0
        "
      >
        <template v-if="field.btText && field.btEvent" #button>
          <span
            class="input-button"
            :style="`color: ${btColor || '#1890ff'}`"
            >{{ field.btText }}</span
          >
        </template>
      </van-field>
      <!-- 选择 -->
      <van-field
        v-else-if="selectFields.includes(field.type)"
        :key="`select-${index}`"
        v-model="textData[field.key]"
        :label="field.label"
        :placeholder="
          field.placeholder ? field.placeholder : `请选择${field.label}`
        "
        :rules="field.required ? required : []"
        :required="field.required"
        :label-width="field.labelWidth ? field.labelWidth : '6.8em'"
        :input-align="field.align ? field.align : 'right'"
        :disabled="field.disabled"
        readonly
        is-link
        @click="anchorEnums(field)"
      />
      <van-field
        v-else-if="['upload', 'mult-upload'].includes(field.type)"
        :key="`upload-${index}`"
        :label="field.label"
        :rules="field.required ? required : []"
        :required="field.required"
        :input-align="field.align ? field.align : 'left'"
        :label-width="field.labelWidth ? field.labelWidth : '6.8em'"
      >
        <template #input>
          <Upload
            v-model="files[field.key]"
            previewVali="mobile"
            :maxCount="field.limit"
            :isDefaultAccept="false"
            :accept="acceptFn(field.accept)"
            @upload="uploadFunction($event, field)"
            @del="deleteFile($event, field)"
          />
          <MultUpload
            v-model="files[field.key]"
            previewVali="mobile"
            :isDefaultAccept="false"
            :accept="acceptFn(field.accept)"
            @upload="uploadFunction($event, field)"
            @del="deleteFile($event, field)"
          />
        </template>
      </van-field>
      <!-- 自定义 -->
      <template v-else-if="field.type == 'custom'">
        <slot :name="`field-custom-${field.key}`" />
      </template>
    </template>
    <PopupPicker
      v-model="pickerView"
      :columns="pickerColumns"
      @confirm="pickerConfirm"
    />
    <PopupDatePicker
      v-model="datePickerView"
      @confirm="datePickerConfirm"
      :config="datePickerConfig"
    />
    <PopupRegionPicker
      v-model="regionPickerView"
      @confirm="regionPickerConfirm"
      :keys="regionKeys"
      :columnsNum="regionColNum"
    />
  </div>
</template>
<script>
import { fieldsMixin } from "./mixin";
import("./style.scss");
export default {
  name: "Fields",
  mixins: [fieldsMixin]
};
</script>
