export const fieldsMixin = {
  props: {
    // 需要从外部引入的值 [{name: '张三'}]
    injectData: {
      type: Array,
      default: () => []
    },
    // 需要返回外部的值 ['name', 'idcard]
    externalData: {
      type: Array,
      default: () => []
    },
    // 需要大写处理的字段
    upperCaseKeys: {
      type: Array,
      default: () => []
    },
    fields: {
      type: [Array, Object],
      default: () => []
    },
    defaultForm: {
      type: [Object, String, null, undefined],
      default: () => {}
    },
    defaultText: {
      type: [Object, String, null, undefined],
      default: () => ({})
    },
    formName: {
      type: [String],
      default: ""
    },
    formShowTitle: {
      type: [String],
      default: ""
    },
    classes: {
      type: [String, Array],
      default: ""
    }
  },
  components: {
    PopupPicker: () => import("../PopupPicker.vue"),
    PopupDatePicker: () => import("../PopupDatePicker.vue"),
    PopupRegionPicker: () => import("../PopupRegionPicker.vue"),
    Upload: () => import("../Upload.vue"),
    MultUpload: () => import("../MultUpload.vue")
  },
  computed: {
    injKeys() {
      return this.injectData.map((e) => e.key);
    },
    _fields() {
      const { fields } = this.$props;
      return [...fields];
    },
    formData: {
      get() {
        return this.defaultForm;
      },
      set(v) {
        this.$emit("updateForm", v); // 手动更新
        this.$emit("update:defaultForm", v); // 自动更新
      }
    },
    textData: {
      get() {
        let defaultText = this.defaultText || {};
        let computedText = {};
        for (let i = 0; i < this._fields.length; i++) {
          const item = this._fields[i];
          if (item.computedText && typeof item.computedText == "function") {
            computedText[item.key] = item.computedText();
            continue;
          } else if (defaultText[item.key]) {
            computedText[item.key] = defaultText[item.key];
            continue;
          }
          computedText[item.key] = this.defaultForm[item.key];
        }
        return computedText;
      },
      set(v) {
        this.$emit("updateText", v); // 手动更新
        this.$emit("update:defaultText", v); // 自动更新
      }
    }
  },
  data() {
    return {
      required: [
        {
          required: true
        }
      ],
      // 输入字段类型
      enterFields: ["text", "textarea", "number", "amount"],
      // 选择字段类型
      selectFields: ["date", "select", "region", "datetime", "radio"],

      files: {},

      pickerView: false,
      pickerColumns: [],
      datePickerView: false,
      datePickerConfig: {},
      regionPickerView: false,
      regionKeys: [],
      regionColNum: 3
    };
  },
  methods: {
    acceptFn(accept) {
      if (accept == "PIC") {
        return ".jpg, .jpeg, .png";
      } else if (accept == "VIDEO") {
        return "video/*";
      } else if (accept == "DOC") {
        return ".pdf,.xlsx,.csv,.xls";
      } else {
        return "image/*,video/*";
      }
    },
    uploadFunction(param, field) {
      const { url } = param;
      this.$set(this.formData, field.key, url);
    },
    deleteFile(index, field) {
      this.files[field.key].splice(index, 1);
      this.$set(this.formData, field.key, "");
    },
    upperCase(key) {
      this.formData[key] = this.formData[key].toLocaleUpperCase();
    },
    getReadonlyVal(field) {
      const { type, key } = field;
      if (this.enterFields.includes(type)) return this.formData[key] || "-";
      else if (this.selectFields.includes(type))
        return this.textData[key] || "-";
    },
    anchorEnums(field) {
      const { type, key } = field;
      this.currentKey = key;
      if (["date", "datetime"].includes(type)) {
        const config = field.config || {};
        this.datePickerConfig = config;
        this.datePickerView = true;
        return;
      }
      if (["region"].includes(type)) {
        this.regionKeys = field.keys || [];
        this.regionColNum = field.regionColNum || 3;
        this.regionPickerView = true;
        return;
      }
      const enums = field.enums;
      this.pickerColumns = enums;
      this.pickerView = true;
    },
    inputChange(val, field) {
      if (field.change && typeof field.change === "function") {
        field.change(val);
      }
    },
    changeFn(item) {
      const field = this.fields.find((e) => e.key == this.currentKey);
      const fn = field.change;
      if (typeof fn == "function") fn(item);
      this.$emit("updateText", this.textData); // 手动更新
      this.$emit("update:defaultText", this.textData); // 自动更新
    },
    pickerConfirm(item) {
      const { value, text } = item;
      this.$set(this.formData, this.currentKey, value);
      this.$set(this.textData, this.currentKey, text);
      this.$emit("change", { value, text }, this.currentKey);
      this.changeFn(item);
    },
    datePickerConfirm(date) {
      this.$set(this.formData, this.currentKey, date);
      this.$set(this.textData, this.currentKey, date);
      this.$emit("change", date, this.currentKey);
      this.changeFn(date);
    },
    regionPickerConfirm(codes, names) {
      const field = this.fields.find((e) => e.key == this.currentKey);
      const { keys, namesKey } = field;

      if (keys)
        keys.map((k, i) => {
          let v = codes[i];
          this.$set(this.formData, k, v);
        });
      if (namesKey)
        namesKey.map((k, i) => {
          let v = names[i];
          this.$set(this.formData, k, v);
        });
      this.$set(this.textData, this.currentKey, names.join("/"));
      this.$emit("change", { codes, names }, this.currentKey);
      this.changeFn({ codes, names });
    },
    validate() {
      const requiredArr = this.fields
        .filter((e) => e.required)
        .map((e) => ({ key: e.key, label: e.label }));
      const validArr = this.fields
        .filter((e) => e.validator)
        .map((e) => ({ key: e.key, validator: e.validator }));
      const formName = this.formName;
      let pre = formName ? `${formName}, ` : "";
      for (let i = 0; i < requiredArr.length; i++) {
        const item = requiredArr[i];
        const v = this.formData[item.key];
        if (!v) throw `${pre}${item.label}不可为空`;
      }

      for (let i = 0; i < validArr.length; i++) {
        const item = validArr[i];
        item.validator(this.formData[item.key], pre);
      }
    }
  }
};
